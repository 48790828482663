
import { generateId } from '@/util-functions/misc-utils';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      required: false,
    },
    autocomplete: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<TextValue[]>,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showOptionalLabel: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: generateId(),
      isDropdownActive: false,
      localValue: this.items.find((item) => item.value === this.value)?.text || '',
    };
  },
  watch: {
    value(v) {
      this.localValue = this.items.find((item) => item.value === v)?.text || '';
    },
  },
  methods: {
    onSelect(value: any) {
      this.isDropdownActive = false;
      this.$emit('change', value);
      this.$emit('input', value);
    },
    onAutoComplete(e: Event) {
      const autoCompleteValue: string = (e.target as HTMLInputElement).value || '';
      if (autoCompleteValue) {
        const matchingItem = this.items.find((i) => i.text === autoCompleteValue);        
        if (matchingItem?.value) {
          this.$emit('input', matchingItem.value);
        }
      }
    },
  },
});
