
import Vue from 'vue';
import SitchTooltip from '@/components/SitchTooltip.vue';

export default Vue.extend({
  components: { SitchTooltip },
  props: {
    value: {
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showOptionalLabel: {
      type: Boolean,
      default: false,
    },
    uppercaseInput: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    content: string | number;
    isFocused: boolean;
  } {
    let getDefaultValue: string | number = '';
    if (this.value) {
      getDefaultValue = this.value as string | number;
    } else if (this.type === 'number') {
      getDefaultValue = 0;
    }
    return {
      content: getDefaultValue,
      isFocused: false,
    };
  },
  computed: {
    showFocusPlaceholder(): boolean {
      if (this.type !== 'number') {
        return Boolean((this.content as string).length) && this.isFocused;
      }
      return this.isFocused;
    },
  },
  watch: {
    value(v) {
      this.content = v;
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    updateContent(val: string | number): string | number {
      if (this.type !== 'number') {
        let typedVal = val as string;
        return this.uppercaseInput ? typedVal.toUpperCase() : typedVal;
      }
      let typedVal = val as number;
      const numberValue = Number(typedVal);
      if (numberValue < this.min) {
        return this.min;
      } else if (numberValue > this.max) {
        return this.max;
      }
      return numberValue;
    },
    onChange(e: any) {
      this.content = this.updateContent(e.target.value);
      this.$emit('change', this.content);
    },
    handleInput(e: any) {
      this.$emit('input', this.updateContent(e.target.value));
    },
  },
});
