import Vue from 'vue';
import messages from '@/translations';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getLocalStorageItem, store, storeEventBus } from '@/store';
import { router, allRoutes } from '@/router';
import VueI18n from 'vue-i18n';
import App from '@/App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faStar,
  faClock,
  faCheck,
  faCreditCard,
  faPlus,
  faMinus,
  faPaperPlane,
  faMapMarker,
  faTimes,
  faBan,
  faLockAlt,
  faShoppingCart,
  faTrash,
  faHandPointUp,
  faChevronRight,
  faChevronDown,
  faCashRegister,
  faCalendarTimes,
  faArrowLeft,
  faChevronLeft,
  faQuestion as faQuestionSolid,
  faExpand,
  faCompress,
  faUsdSquare,
  faSync,
  faUser,
  faMicrophone,
  faSquare,
  faSparkles,
  faUndo,
  faUpload,
  faEye,
  faEyeSlash,
  faCoin,
  faPenAlt,
  faPencil,
  faSignOut,
  faBell,
  faBellSlash,
  faUserPlus as faUserPlusSolid,
  faDownload,
  faQrcode,
  faArrowAltRight,
  faPrint,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faBars,
  faMobileAndroid,
  faMobile,
  faBrowser as faBrowserRegular,
  faUserPlus,
  faShareAlt,
  faEnvelope as faEnvelopeRegular,
  faAddressCard,
  faTimes as faTimesRegular,
  faClock as faClockRegular,
  faLink,
  faArchive,
  faHandshake,
  faWifi,
  faList,
  faLayerGroup,
  faStore,
  faExpandArrows,
  faImages,
  faCopy,
  faCalendarAlt,
  faMapMarkerAlt,
  faCalendarPlus,
  faClipboardList,
  faChartNetwork,
  faQuestion as faQuestionRegular,
  faMoneyBillWave,
  faLanguage,
  faNewspaper,
  faMegaphone,
  faInfoCircle,
  faHandPaper,
  faWindowMaximize,
  faCode,
  faUserMinus,
  faPhoneOffice,
  faChess,
  faComments,
  faShare,
  faHistory,
  faSignIn,
  faBorderAll,
  faBackspace,
  faUsers,
  faQuestionSquare,
  faBookOpen,
  faSuitcase,
  faCommentsAlt,
  faBuilding,
} from '@fortawesome/pro-regular-svg-icons';
import 'normalize.css';
import SitchCheckbox from '@/components/SitchCheckbox.vue';
import SitchPayment from '@/components/SitchPayment.vue';
import SitchModal from '@/components/SitchModal.vue';
import SitchAccordion from '@/components/SitchAccordion.vue';
import SitchTooltip from '@/components/SitchTooltip.vue';
import SitchDropdown from '@/components/SitchDropdown.vue';
import SitchInput from '@/components/SitchInput.vue';
import SitchAvatar from '@/components/SitchAvatar.vue';
import Spacer from '@/components/Spacer.vue';
import { eInteractionMethods, eModeType, eTranslationLocaleCode } from '../enums';
import { productionFirebaseCredentials, stagingFirebaseCredentials } from '../firebase-credentials';
// import { demoAccountUserId, endpoints } from '../constants';
import { demoAccountUserId } from '../constants';
import { faApple, faBitcoin, faEthereum, faGoogle, faPaypal, faThreads } from '@fortawesome/free-brands-svg-icons';
import { collection, doc, DocumentData, DocumentReference, Firestore, FirestoreSettings, getDoc, getFirestore, initializeFirestore } from 'firebase/firestore';
import { setUpNotifications, showError, showGlobalModal } from './notice-utils';
import { addHttp, getSubscriptionData, getPayServiceLink, getVenmoLink } from './misc-utils';
import { hideLoading, showLoading } from './loading-utils';
import { initTheme } from './theme-utils';
// import { standardApiFetch } from './api-utils';

library.add(
  faStar,
  faLockAlt,
  faMobileAndroid,
  faMobile,
  faBrowserRegular,
  faUserPlus,
  faUserMinus,
  faShareAlt,
  faBars,
  faTimes,
  faTimesRegular,
  faAddressCard,
  faLink,
  faArchive,
  faHandshake,
  faWifi,
  faList,
  faLayerGroup,
  faEnvelopeRegular,
  faStore,
  faCheck,
  faCreditCard,
  faPlus,
  faMinus,
  faMoneyBillWave,
  faPaypal,
  faPaperPlane,
  faMapMarker,
  faExpandArrows,
  faImages,
  faBan,
  faCopy,
  faShoppingCart,
  faTrash,
  faCalendarAlt,
  faMapMarkerAlt,
  faClock,
  faCalendarPlus,
  faClipboardList,
  faChartNetwork,
  faQuestionRegular,
  faLanguage,
  faNewspaper,
  faMegaphone,
  faBitcoin,
  faHandPointUp,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faCashRegister,
  faCalendarTimes,
  faArrowLeft,
  faClockRegular,
  faQuestionSolid,
  faExpand,
  faCompress,
  faInfoCircle,
  faHandPaper,
  faWindowMaximize,
  faCode,
  faUsdSquare,
  faSync,
  faUser,
  faPhoneOffice,
  faMicrophone,
  faSquare,
  faApple,
  faSparkles,
  faUndo,
  faUpload,
  faChess,
  faUndo,
  faEye,
  faEyeSlash,
  faComments,
  faEthereum,
  faCoin,
  faPenAlt,
  faShare,
  faHistory,
  faSignIn,
  faGoogle,
  faBorderAll,
  faBackspace,
  faPencil,
  faUsers,
  faSignOut,
  faQuestionSquare,
  faBookOpen,
  faBell,
  faBellSlash,
  faUserPlusSolid,
  faSuitcase,
  faDownload,
  faQrcode,
  faArrowAltRight,
  faCommentsAlt,
  faPrint,
  faThreads,
  faBuilding,
);

Vue.component('sitch-accordion', SitchAccordion);
Vue.component('sitch-dropdown', SitchDropdown);
Vue.component('sitch-tooltip', SitchTooltip);
Vue.component('sitch-checkbox', SitchCheckbox);
Vue.component('sitch-payment', SitchPayment);
Vue.component('sitch-avatar', SitchAvatar);
Vue.component('sitch-modal', SitchModal);
Vue.component('sitch-input', SitchInput);
Vue.component('spacer', Spacer);
Vue.component('fa', FontAwesomeIcon);
Vue.config.productionTip = false;

export const eventBus = storeEventBus;
export let currFirestore: Firestore;
export let firebaseApp: FirebaseApp;
export const isProductionEnv = process.env.NODE_ENV === 'production' && window.location.hostname.includes('sitch.app');
export const apiUrl = isProductionEnv ? 'https://sitch-api-dot-sitch-app.appspot.com' : 'https://sitch-test-api-dot-sitch-app-test-64014.uc.r.appspot.com';
// export const apiUrl = isProductionEnv ? 'https://sitch-api-dot-sitch-app.appspot.com' : 'http://localhost:3001';
export const sitchClientUrl = isProductionEnv ? 'https://sitch.app' : process.env.NODE_ENV === 'production' ? 'https://sitch-client-test.web.app' : 'http://localhost:8081';
const sitchManagerUrl = isProductionEnv ? 'https://mysitch.app' : process.env.NODE_ENV === 'production' ? 'https://sitch-manager-test.web.app/' : 'http://localhost:8080';

export const getStripePublicKey = (): string => {
  // thU6a3OaF8VvUUEmf67xhM7YPVX2 Is the demo account.
  if (isProductionEnv && store.state.userId !== demoAccountUserId) {
    return 'pk_live_0LQsfW6fOZ3SoXXSWppc2pfc008MdjqRHy';
  }
  return 'pk_test_0QF6AtxxbfkdYAMWSE527WJW00kM5SxAsn';
};

if (isProductionEnv) {
  const fireStoreSettings: FirestoreSettings = { ignoreUndefinedProperties: true };
  firebaseApp = initializeApp(productionFirebaseCredentials);
  currFirestore = initializeFirestore(firebaseApp, fireStoreSettings);
} else {
  firebaseApp = initializeApp(stagingFirebaseCredentials);
  currFirestore = getFirestore(firebaseApp);
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  messages,
});

store.commit('i18n', i18n);

export let t: any = messages.en;

export const setDefaultLanguage = () => {
  const browserLang = navigator.language || navigator.languages?.[0] || eTranslationLocaleCode.en;
  const localeCode = (getLocalStorageItem('locale') || browserLang).split('-')[0] as eTranslationLocaleCode;
  store.commit('locale', localeCode);
};

export const changeLanguage = async () => {
  const localeCode: eTranslationLocaleCode = store.state.locale;

  if (!localeCode) {
    return;
  }

  const setLocale = () => {
    store.state.i18n.locale = localeCode;
    t = (messages as any)[localeCode];
  };

  const alreadyFetchedLanguage = Boolean(Object.keys(store.state.i18n.messages[localeCode]).length);
  if (!alreadyFetchedLanguage) {
    showLoading();
    getDoc(doc(currFirestore, 'clientTranslations', localeCode))
      .then((docSnap) => {
        if (docSnap.exists()) {
          const translationsObject: any = {
            ...messages.en, // Merge to fill in any missing translations.
            ...docSnap.data(),
          };
          store.state.i18n.setLocaleMessage(localeCode, translationsObject);
          setLocale();
        } else {
          showError('No data in the database for that language.');
        }
      })
      .catch((error: any) => {
        showError(`Fetching translations unsuccessful. ${error?.message || error}`);
      })
      .finally(() => {
        hideLoading();
      });
  } else {
    setLocale();
  }
};

export const getParameterByName = (n: string, givenUrl = ''): string => {
  const url: string = givenUrl || window.location.href;
  const name = n.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return '';
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' ')) || '';
};

/*
When in an iframe we need to read from urls passed as messages from the parent to determine mode behaviour.
window.location.href cannot be trusted within an iframe. So for params that are read in sitch-client
initialization or when setting a mode use this function instead of getParameterByName.
*/
export let providedUrl = window.location.href;
function getParamFromSitchUrlByName(n: string) {
  return getParameterByName(n, providedUrl);
}

export const getSitchUrlParts = (providedUrlArg = '') => {
  providedUrl = providedUrlArg || window.location.href; // Default argument fails in the case of an empty string, so we need this.
  const url = new URL(addHttp(providedUrl));
  const pathnamePartWithoutFirstSlash = url.pathname.substring(1);
  const pathNameParts = pathnamePartWithoutFirstSlash.split('/');
  const firstPartOfPathName = pathNameParts[0];
  let shortPermalinkId: string = getParamFromSitchUrlByName('s');
  const urlHasGenericSingleModeLinkId = firstPartOfPathName === 's';
  if (urlHasGenericSingleModeLinkId) {
    // In case there's a query sting on the end of this id, we don't want to include it. So split after '?'.
    shortPermalinkId = pathNameParts[1].split('?')[0];
  }
  const activeModeId: string = getParamFromSitchUrlByName('am');
  const modeToWrapOwnerId: string = getParamFromSitchUrlByName('mtwoi');
  const modeToWrapId: string = getParamFromSitchUrlByName('mtw');
  // Only accept a permalink id in the form of /{idName} if the url isn't using the /s/{id} format.
  let customPermalinkId: string = getParamFromSitchUrlByName('p') || (urlHasGenericSingleModeLinkId ? '' : firstPartOfPathName);
  const genericId: string = getParamFromSitchUrlByName('g');
  const multiPartDevicePartId: string = getParamFromSitchUrlByName('par');
  const userId = getParamFromSitchUrlByName('u');
  const itemId = getParamFromSitchUrlByName('i');
  const interactionMethod = Number(getParamFromSitchUrlByName('m')) || eInteractionMethods.link;
  const isEmbedded = Boolean(getParamFromSitchUrlByName('e'));
  const embeddedWidth = Number(getParamFromSitchUrlByName('ew')) || 0;
  const wasGivenViaInAppQrCode = getParamFromSitchUrlByName('iaqrc') === 'true';
  const orderBreakdownInUrl = getParamFromSitchUrlByName('ob') ? decodeURIComponent(getParamFromSitchUrlByName('ob')) : '';
  const promoCodeInUrl = getParamFromSitchUrlByName('c');
  const itemNameInUrl = getParamFromSitchUrlByName('itemName');
  const backgroundColor = getParamFromSitchUrlByName('bgc').replaceAll('%23', '#');
  const noTrack = getParameterByName('noTrack') || false;
  if (orderBreakdownInUrl) {
    store.commit('orderBreakdownInUrl', JSON.parse(orderBreakdownInUrl));
  }
  if (itemId) {
    store.commit('itemId', itemId);
  }
  if (itemNameInUrl) {
    store.commit('itemNameInUrl', itemNameInUrl.toLowerCase().replaceAll('_', ' '));
  } else {
    store.commit('itemNameInUrl', '');
  }
  if (backgroundColor) {
    const docEl = document.documentElement as HTMLElement;
    docEl.style.setProperty('--backgroundColor', backgroundColor);
    docEl.style.setProperty('--inputBackgroundColor', backgroundColor);
    document.body.style.background = backgroundColor;
  }
  store.commit('promoCodeInUrl', promoCodeInUrl);
  store.commit('isEmbedded', isEmbedded);
  store.commit('embeddedWidth', embeddedWidth);
  store.commit('wasGivenViaInAppQrCode', wasGivenViaInAppQrCode);
  store.commit('noTrack', noTrack);
  store.commit('interactionMethod', interactionMethod);
  store.commit('genericId', genericId);

  const reserveNames = ['Checkers', 'SmartHome', 'Hue'];

  // It's not a real permalink id if this is the case.
  if ([...allRoutes, ...reserveNames, 'LinkActions'].includes(customPermalinkId)) {
    customPermalinkId = '';
  }

  return {
    multiPartDevicePartId,
    customPermalinkId,
    shortPermalinkId,
    modeToWrapOwnerId,
    modeToWrapId,
    activeModeId,
    genericId,
    userId,
  };
};

export const getAndSetUrlData = (url = '') => {
  const urlParts = getSitchUrlParts(url);
  multiPartDevicePartId = urlParts.multiPartDevicePartId;
  customPermalinkId = urlParts.customPermalinkId;
  shortPermalinkId = urlParts.shortPermalinkId;
  modeToWrapOwnerId = urlParts.modeToWrapOwnerId;
  modeToWrapId = urlParts.modeToWrapId;
  activeModeId = urlParts.activeModeId;
  genericId = urlParts.genericId;
  userId = urlParts.userId;
};

let multiPartDevicePartId = '';
let customPermalinkId = '';
let shortPermalinkId = '';
export let modeToWrapOwnerId = '';
export let modeToWrapId = '';
let activeModeId = '';
let genericId = '';
let userId = '';
getAndSetUrlData();

const queryStringAmount = Number(getParamFromSitchUrlByName('amount') || 0);
store.commit('queryStringAmount', queryStringAmount);
store.commit('firstLoadHadActiveModeInUrl', Boolean(activeModeId || shortPermalinkId || customPermalinkId));

function getActiveMode() {
  if (userId) {
    getDoc(doc(currFirestore, 'publicUserModeGateways', userId))
      .then((docSnap) => {
        if (docSnap.exists()) {
          const publicUserModeGateway: PublicUserModeGateway = docSnap.data() as PublicUserModeGateway;
          publicUserModeGateway.docId = docSnap.id; // Fallback since some gateways in the testing environment erroneously don't have docIds.
          setModeFromGateway(publicUserModeGateway, docSnap.ref, activeModeId, userId);
        } else {
          finishedModeAcquisitionProcess();
          showGlobalModal(t.error, t.userDoesNotExist);
        }
      })
      .catch(() => {
        hideLoading();
        showError('Could not get the gateway doc for active mode.');
      });
  } else {
    finishedModeAcquisitionProcess();
  }
}

// We either do this on initial load because we determine the current mode from the url, or if a redirect sitch uses a sitch link.
function setModeFromGateway(publicUserModeGateway: PublicUserModeGateway, publicUserModeGatewayDoc: DocumentReference<DocumentData> | null = null, modeId = '', givenUserId = '') {
  // It's important we only set these once, because a Team Mode will do another gateway lookup and we don't want that one overriding the original.
  if (!store.state.publicUserModeGateway.docId) {
    store.commit('userId', givenUserId);
    store.commit('publicUserModeGateway', publicUserModeGateway);
    store.commit('publicUserModeGatewayDoc', publicUserModeGatewayDoc);
    getSubscriptionData(publicUserModeGateway);
  }
  if (!modeId && !publicUserModeGateway.isSitchLinkActivated) {
    finishedModeAcquisitionProcess();
    showGlobalModal(t.thisLinkIsNotYetActivatedTitle, t.thisLinkIsNotYetActivatedDescription);
  } else {
    setMode(publicUserModeGateway, modeId, givenUserId);
  }
}

export const getMode = (sitchAppUrl = '') => {
  showLoading();

  if (sitchAppUrl) {
    // Get mode always uses a url string to figure out what mode to get, but if it's not passed it's just grabbed from the url when getAndSetUrlData() is called in the early initialization of this file.
    getAndSetUrlData(sitchAppUrl);
  }

  // If we have a genericId get the userId and then the active mode.
  if (genericId) {
    if (genericId === 'demoQrCode') {
      window.location.replace(`https://sitch.cards`);
      return;
    }
    getDoc(doc(currFirestore, 'genericIdToUserIdMappings', genericId)).then((docSnap) => {
      if (docSnap.exists()) {
        const genericIdToUserIdMapping = docSnap.data() as GenericIdData;
        userId = genericIdToUserIdMapping.userId.trim();
        store.commit('genericIdToUserIdMapping', genericIdToUserIdMapping);
        if (userId !== 'TBD') {
          getActiveMode();
        } else {
          if (window.location.origin === 'https://sitch-client-test.web.app') {
            window.location.replace(`https://sitch-manager-test.web.app/?g=${genericId}`);
          } else {
            window.location.replace(`${sitchManagerUrl}/?g=${genericId}`);
          }
        }
      } else {
        finishedModeAcquisitionProcess(true);
        showError('Generic id does not exist');
      }
    });
    // If we already have user Id then we can just go straight to getting the active mode
  } else if (userId) {
    getActiveMode();
    // If we have a permalink then get the userId and the modeId associated with it.
  } else if (shortPermalinkId) {
    getDoc(doc(currFirestore, 'shortPermalinks', shortPermalinkId)).then((docSnap) => {
      if (docSnap.exists()) {
        const localUserId = (docSnap.data() as Permalink).userId.trim();
        const modeId = (docSnap.data() as Permalink).modeId.trim();
        getDoc(doc(currFirestore, 'publicUserModeGateways', localUserId))
          .then((docSnap) => {
            const publicUserModeGateway: PublicUserModeGateway = docSnap.data() as PublicUserModeGateway;
            setModeFromGateway(publicUserModeGateway, docSnap.ref, modeId, localUserId);
          })
          .catch((error) => {
            showError(`Could not get the gateway doc ${error?.message || error}`);
          });
      } else {
        finishedModeAcquisitionProcess(true);
        showError(`Could not find a Sitch with that ID.`);
      }
    });
  } else if (customPermalinkId) {    
    getDoc(doc(currFirestore, 'customPermalinks', customPermalinkId)).then((docSnap) => {
      if (docSnap.exists()) {
        // userId = (docSnap.data() as Permalink).userId.trim();
        const localUserId = (docSnap.data() as Permalink).userId.trim();
        const modeId = (docSnap.data() as Permalink).modeId.trim();
        getDoc(doc(currFirestore, 'publicUserModeGateways', localUserId)).then((docSnap) => {
          const publicUserModeGateway: PublicUserModeGateway = docSnap.data() as PublicUserModeGateway;
          setModeFromGateway(publicUserModeGateway, docSnap.ref, modeId, localUserId);
        });
      } else {
        finishedModeAcquisitionProcess(true);
        showError(`Could not find a Sitch with that ID.`);
      }
    });
  } else {
    finishedModeAcquisitionProcess();
  }
};

export const setMode = async (publicUserModeGateway: PublicUserModeGateway, modeId = '', userId = '') => {
  const modesCollection = collection(currFirestore, 'publicUserModeGateways', userId || store.state.userId, 'modes');
  let document;
  let linkId: string;
  // If modeId is given, always just use that.
  if (modeId) {
    if (modeId !== store.state.mode?.docId) {
      document = doc(currFirestore, modesCollection.path, modeId);
    } else {
      // Don't do anything if we have already loaded this mode in.
      finishedModeAcquisitionProcess();
      return;
    }
  } else {
    if (publicUserModeGateway.activeModeOwnerId && !publicUserModeGateway.wrapperModeId) {
      linkId = publicUserModeGateway.activeModeLinkId;
      getMode(`${sitchClientUrl}/s/${linkId}`);
      return;
    }

    const genericIdToUserIdMapping = store.state.genericIdToUserIdMapping;
    const multiPartModeAssignments = genericIdToUserIdMapping?.multiPartModeAssignments;

    let assignedModeId = genericIdToUserIdMapping?.assignedModeId;
    let assignedLinkId = genericIdToUserIdMapping?.assignedLinkId;
    let assignedModeOwnerId = genericIdToUserIdMapping?.assignedModeOwnerId;

    if (multiPartModeAssignments && multiPartDevicePartId) {
      // If this is a multipart device, get the appropriate active mode.
      const modeAssignment: ModeAssignment = multiPartModeAssignments[multiPartDevicePartId];
      if (modeAssignment) {
        assignedModeId = modeAssignment.assignedModeId;
        assignedLinkId = modeAssignment.assignedLinkId;
        assignedModeOwnerId = modeAssignment.assignedModeOwnerId;
      }
    }
    if (assignedModeId) {
      // If this device was assigned a mode to open, then use that.
      if (assignedModeOwnerId) {
        // If the mode belongs to another user we have to use the link id and do a redirect.
        getMode(`${sitchClientUrl}/s/${assignedLinkId}`);
        return;
      } else {
        document = doc(currFirestore, modesCollection.path, assignedModeId);
      }
    } else if (publicUserModeGateway.wrapperModeOwnerId && publicUserModeGateway.wrapperModeId) {
      document = doc(currFirestore, 'publicUserModeGateways', publicUserModeGateway.wrapperModeOwnerId, 'modes', publicUserModeGateway.wrapperModeId);
    } else if (publicUserModeGateway.wrapperModeId) {
      document = doc(currFirestore, modesCollection.path, publicUserModeGateway.wrapperModeId);
    } else if (publicUserModeGateway.activeModeOwnerId && publicUserModeGateway.activeModeId) {
      document = doc(currFirestore, 'publicUserModeGateways', publicUserModeGateway.activeModeOwnerId, 'modes', publicUserModeGateway.activeModeId);
    } else if (publicUserModeGateway.activeModeId) {
      document = doc(currFirestore, modesCollection.path, publicUserModeGateway.activeModeId);
    } else {
      finishedModeAcquisitionProcess();
      showGlobalModal(t.noActiveSitch, t.noActiveSitchInfo);
      return;
    }
  }

  getDoc(document)
    .then((docSnap) => {
      if (docSnap.exists()) {
        const currentMode: AnyMode = docSnap.data() as AnyMode;

        const defaultFunc = () => {
          store.commit('customPermalinkId', customPermalinkId);
          commitMode(currentMode);
          finishedModeAcquisitionProcess();
        };
        switch (currentMode.type) {
          case eModeType.urlRedirect: {
            // Using replace since normal back behaviour would just create another redirect.
            const urlRedirectMode = currentMode as UrlRedirectMode;
            const url = urlRedirectMode.redirectUrl as string;
            if (url.includes('sitch.app')) {
              getMode(url);
            } else {
              window.location.replace(addHttp(url));
            }
            break;
          }
          case eModeType.personalPayment: {
            const personalPaymentMode = currentMode as PersonalPaymentsMode;
            // If just paypalEnabled.
            if (!personalPaymentMode.interacEnabled && !personalPaymentMode.bitcoinEnabled && !personalPaymentMode.venmoEnabled && !personalPaymentMode.cashAppEnabled) {
              window.location.replace(getPayServiceLink(personalPaymentMode, personalPaymentMode.paypalMeUrl));
              // If just cashAppEnabed.
            } else if (!personalPaymentMode.interacEnabled && !personalPaymentMode.bitcoinEnabled && !personalPaymentMode.venmoEnabled && !personalPaymentMode.paypalEnabled) {
              window.location.replace(getPayServiceLink(personalPaymentMode, personalPaymentMode.cashAppUrl));
              // If just venmoEnabled.
            } else if (!personalPaymentMode.interacEnabled && !personalPaymentMode.bitcoinEnabled && !personalPaymentMode.cashAppEnabled && !personalPaymentMode.paypalEnabled) {
              window.location.replace(getVenmoLink(personalPaymentMode));
            } else {
              defaultFunc();
            }
            break;
          }
          case eModeType.site: {
            setUpCurrentSiteSitch(currentMode as SiteMode);
            break;
          }
          default:
            defaultFunc();
            break;
        }
      } else {
        getMode(`${sitchClientUrl}/s/${linkId}`);
        showError('We cannot find that Sitch.');
      }
    })
    .catch((error) => {
      showError(`Could not get the mode doc. ${error?.message || error}`);
    });
};

function setUpCurrentSiteSitch(siteMode: SiteMode) {
  store.commit('currSite', siteMode);

  const sitePages: AnyMode[] = [];
  const siteModeIdsThatNoLongerExist: string[] = [];
  const promiseArray: Promise<any>[] = [];
  const wrapperModeId = store.state.publicUserModeGateway?.wrapperModeId;
  const wrapperModeOwnerId = store.state.publicUserModeGateway?.wrapperModeOwnerId;
  const activeModeId = store.state.publicUserModeGateway?.activeModeId;
  const activeModeOwnerId = store.state.publicUserModeGateway?.activeModeOwnerId;
  const siteIsWrapper = wrapperModeId === siteMode.docId;
  const siteIsActive = activeModeId === siteMode.docId;
  let gatewayId = store.state.publicUserModeGateway.docId;
  if (siteIsActive && activeModeOwnerId) {
    gatewayId = activeModeOwnerId;
  }
  if (siteIsWrapper && wrapperModeOwnerId) {
    gatewayId = wrapperModeOwnerId;
  }
  const modesCollection = collection(currFirestore, 'publicUserModeGateways', gatewayId, 'modes');

  siteMode.sitePageModeIds.forEach((sitePageModeId) => {
    promiseArray.push(
      getDoc(doc(currFirestore, modesCollection.path, sitePageModeId))
        .then((docSnap) => {
          if (docSnap.exists()) {
            sitePages.push(docSnap.data() as AnyMode);
          } else {
            siteModeIdsThatNoLongerExist.push(sitePageModeId);
          }
        })
        .catch((error: any) => {
          showError(`Could not get one of the pages for this site. ${error?.message || error}`);
        })
    );
  });

  const onSiteModesReady = () => {
    Promise.all(promiseArray).then(() => {
      const currSitePageModeId = getParamFromSitchUrlByName('sp');
      store.commit('sitePages', sitePages);
      store.commit('customPermalinkId', customPermalinkId);

      if (siteModeIdsThatNoLongerExist.length) {
        // TODO: Make a server call here to verify and purge these.
      }

      const firstCheck = sitePages.find((sitePage) => sitePage.docId === currSitePageModeId);
      const secondCheck = sitePages.find((sitePage) => modeToWrapOwnerId === sitePage.linkId);
      const thirdCheck = sitePages.find((sitePage) => sitePage.docId === modeToWrapId);
      const fourthCheck = sitePages.find((sitePage) => sitePage.docId === activeModeId);
      const landingMode = firstCheck || secondCheck || thirdCheck || fourthCheck;
      commitMode(landingMode || sitePages.find((sitePage) => sitePage.docId === siteMode.landingPageModeId));

      finishedModeAcquisitionProcess();
    });
  };

  const onModeToBeWrappedDocReady = (modeToBeWrappedDoc: DocumentReference<DocumentData>) => {
    promiseArray.push(
      getDoc(modeToBeWrappedDoc)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const mode = docSnap.data() as AnyMode;
            // Cannot wrap a site in a site.
            if (mode.type !== eModeType.site) {
              // Don't add the page if it's already in the site.
              if (!sitePages.find((p) => p.docId === mode.docId)) {
                sitePages.push(mode);
              }
            }
          } else {
            siteModeIdsThatNoLongerExist.push(activeModeId);
          }
        })
        .catch((error: any) => {
          showError(`Could not get the wrapper Sitch. ${error?.message || error}`);
        })
        .finally(() => {
          onSiteModesReady();
        })
    );
  };
  let modeToBeWrappedDoc;
  // If there is a wrapperModeId present then this site MUST be the wrapper since sites cannot wrap sites.
  const thereIsAModeToWrap = activeModeId && (wrapperModeId || modeToWrapId || modeToWrapOwnerId);
  if (thereIsAModeToWrap) {
    if (modeToWrapOwnerId && modeToWrapId) {      
      modeToBeWrappedDoc = doc(currFirestore, 'publicUserModeGateways', modeToWrapOwnerId, 'modes', modeToWrapId);
    } else if (activeModeOwnerId) {      
      modeToBeWrappedDoc = doc(currFirestore, 'publicUserModeGateways', activeModeOwnerId, 'modes', activeModeId);
    } else {      
      modeToBeWrappedDoc = doc(currFirestore, 'publicUserModeGateways', store.state.userId, 'modes', activeModeId);
    }    
    onModeToBeWrappedDocReady(modeToBeWrappedDoc);
  } else {
    onSiteModesReady();
  }
}

// export const getExternalModeAndGatewayDocsFromLinkId = (
//   modeLinkId: string
// ): Promise<
//   | {
//       modeDoc: DocumentReference<DocumentData>;
//       gatewayDoc: DocumentReference<DocumentData>;
//     }
//   | undefined
// > => {
//   return new Promise((resolve, reject) => {
//     // If the mode to be wrapped does not belong to the current user then we have to look it up using its shortPermalinkId or its customPermalinkId.
//     const promiseArray: Promise<any>[] = [];
//     let ret:
//       | {
//           modeDoc: DocumentReference<DocumentData>;
//           gatewayDoc: DocumentReference<DocumentData>;
//         }
//       | undefined = undefined;

//     if (modeLinkId) {
//       promiseArray.push(
//         getDoc(doc(currFirestore, 'shortPermalinks', modeLinkId)).then((docSnap) => {
//           if (docSnap.exists()) {
//             const localUserId = (docSnap.data() as Permalink).userId.trim();
//             const modeId = (docSnap.data() as Permalink).modeId.trim();
//             // Change the modeId from the URL to the real mode ID for the landing page.
//             ret = {
//               modeDoc: doc(currFirestore, 'publicUserModeGateways', localUserId, 'modes', modeId),
//               gatewayDoc: doc(currFirestore, 'publicUserModeGateways', localUserId),
//             };
//           } else {
//             showError(`Could not find a Sitch with that ID.`);
//           }
//         })
//       );
//     }

//     Promise.all(promiseArray)
//       .then(() => {
//         resolve(ret);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

function commitMode(newMode: AnyMode | undefined) {
  if (!newMode) {
    return;
  }
  store.commit('mode', newMode);
}

export const routerNavigate = (path: string) => {
  if (store.state.modeChangeWasDueToPopState) {
    // Prevent url changes in the case of a back or forward click since the browser will have the correct url and we don't want to muck up the history with an unnecessary push/replace.
    store.commit('modeChangeWasDueToPopState', false);
    return;
  }
  let query: any = {};
  shortPermalinkId = shortPermalinkId || store.state.publicUserModeGateway.wrapperModeLinkId || store.state.publicUserModeGateway.activeModeLinkId || '';
  if (store.state.currSite?.docId) {
    if (customPermalinkId) {
      query = { p: customPermalinkId, sp: store.state.mode?.docId };
    } else if (shortPermalinkId) {
      query = { s: shortPermalinkId, sp: store.state.mode?.docId };
    } else {
      query = { u: userId, am: store.state.currSite?.docId, sp: store.state.mode?.docId };
    }
  } else {
    if (customPermalinkId) {
      query = { p: customPermalinkId };
    } else if (shortPermalinkId) {
      query = { s: shortPermalinkId };
    } else {
      query = { u: userId, am: store.state.mode?.docId };
    }
  }
  if (store.state.itemId) {
    query.i = store.state.itemId;
  }
  if (modeToWrapId || store.state.publicUserModeGateway.wrapperModeId) {
    query.mtw = modeToWrapId || store.state.publicUserModeGateway.activeModeId;
    query.mtwoi = modeToWrapOwnerId || store.state.publicUserModeGateway.activeModeOwnerId || store.state.publicUserModeGateway.docId;
  }
  const routeCatchFuncToSupressNavDuplicationErrors = (error: any) => {
    // Ignore the vuex err regarding  navigating to the page they are already on.
    if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
      // But print any other errors to the console
      console.error(error);
    }
  };
  if (store.state.isEmbedded || store.state.rootComponent.isFirstNavigation) {
    router.replace({ path, query }).catch(routeCatchFuncToSupressNavDuplicationErrors);
  } else {
    router.push({ path, query }).catch(routeCatchFuncToSupressNavDuplicationErrors);
  }
};

function finishedModeAcquisitionProcess(forceHideAllLoading = false) {
  hideLoading(forceHideAllLoading);
  if (!store.state.mode) {
    initTheme();
  }
  window.parent.postMessage('_sitch_loaded', '*');
  store.commit('settingEmbededMode', false);
  store.commit('finishedModeAcquisitionProcess', true);
}

export const init = () => {
  if (process.env.NODE_ENV === 'production') {
    if ('Notification' in window && Notification.permission === 'granted') {
      setUpNotifications();
    }
  }
  const root = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
  store.commit('rootComponent', root.$children[0]);

  if (window.location.pathname === '/' && !window.location.search) {
    // If the url is simply https://sitch.app or any other domain without a path or query string (test and localhost).
    store.commit('visitedRoot', true);
    finishedModeAcquisitionProcess();
    return;
  } else if (!store.state.isEmbedded) {
    getMode();
  }
  setDefaultLanguage();
};
